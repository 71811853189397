import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import ArgonDashboard from "./argon-dashboard";
import ToastPlugin from 'vue-toast-notification';
import PrimeVue from 'primevue/config';
import Aura from '@primevue/themes/aura';
import '@primevue/themes/aura';

import 'vue-toast-notification/dist/theme-bootstrap.css';


const appInstance = createApp(App);
appInstance.use(ToastPlugin);
appInstance.use(store);
appInstance.use(router);
appInstance.use(ArgonDashboard);
appInstance.use(PrimeVue, {
    theme: {
        preset: Aura
    }
});
appInstance.mount("#app");
