<script setup>
import { computed } from "vue";
import { useRouter, useRoute } from 'vue-router'; // Import useRoute
import { useStore } from "vuex";

import SidenavItem from "./SidenavItem.vue";
import axiosInstance from '../../authAxios';
// import SidenavCard from "./SidenavCard.vue";

const store = useStore();
const isRTL = computed(() => store.state.isRTL);

const route = useRoute(); // Call useRoute outside the function
const getRoute = () => {
  const routeArr = route.path.split("/");
  return routeArr[1];
};

const router = useRouter(); // Router for navigation

const logOut = async () => {
  try {
    const resp = await axiosInstance.post("logout");
    const data = resp.data.status;

    if (data === 'SUCCESS') {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("branch");
      await router.push("/signin"); // Navigate to sign-in page
    }

    console.log(data, 'signOut');
  } catch (err) {
    console.error(err);
  } finally {
    console.log("cant fetch signOut");
  }
};
</script>

<template>
  <div class="collapse navbar-collapse w-auto h-auto h-100" id="sidenav-collapse-main">
    <ul class="navbar-nav">
      <li class="nav-item">
        <sidenav-item to="/dashboard" :class="getRoute() === 'dashboard' ? 'active' : ''" :navText="'Dashboard'">
          <template v-slot:icon>
            <i class="ni ni-tv-2 text-warning text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item to="/myproject" :class="getRoute() === 'myproject' ? 'active' : ''" :navText="'My Project'">
          <template v-slot:icon>
            <i class="ni ni-calendar-grid-58 text-warning text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
      <li class="nav-item">
        <sidenav-item to="/emails" :class="getRoute() === 'emails' ? 'active' : ''" :navText="'Email'">
          <template v-slot:icon>
            <i class="ni ni-email-83 text-warning text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
      <!-- <li class="nav-item">
        <a data-bs-toggle="collapse" href="#ecommerceExamples" aria-controls="ecommerceExamples" aria-expanded="true"
          class="nav-link">
          <div class="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center">
            <i class="ni ni-archive-2 text-warning text-sm opacity-10"></i>
          </div>
          <span class="nav-link-text ms-1">Shop Good</span>
        </a>
        <div class="collapse show" id="ecommerceExamples">
          <ul class="nav ms-4">
            <li class="nav-item">
              <a href="#/ecommerce/overview" class="nav-link">
                <span class="sidenav-mini-icon">O</span>
                <span class="sidenav-normal">Product</span>
              </a>
            </li>
         
            
          </ul>
        </div>
      </li> -->
      <!-- <li class="nav-item">
        <sidenav-item to="/transactions" :class="getRoute() === 'transactions' ? 'active' : ''" :navText="'Transaction'">
          <template v-slot:icon>
            <i class="ni ni-money-coins text-warning text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li> -->
      <!-- 
      <li class="nav-item">
        <sidenav-item to="/billing" :class="getRoute() === 'billing' ? 'active' : ''" :navText="'Billing'">
          <template v-slot:icon>
            <i class="ni ni-credit-card text-warning text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li> -->
      <!-- <li class="nav-item">
        <sidenav-item to="/services" :class="getRoute() === 'services' ? 'active' : ''" :navText="'Services'">
          <template v-slot:icon>
            <i class="ni ni-credit-card text-warning text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li> -->
      <!-- <li class="nav-item">
        <sidenav-item to="/teams" :class="getRoute() === 'teams' ? 'active' : ''" :navText="'Team'">
          <template v-slot:icon>
            <i class="ni ni-credit-card text-warning text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li> -->

      <li class="mt-3 nav-item">


        <h6 class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6" :class="isRTL ? 'me-4' : 'ms-2'">
          ACCOUNT PAGES
        </h6>
      </li>

      <li class="nav-item">
        <sidenav-item to="/profile" :class="getRoute() === 'profile' ? 'active' : ''"
          :navText="isRTL ? 'حساب تعريفي' : 'Profile'">
          <template v-slot:icon>
            <i class="ni ni-single-02 text-dark text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      


      <li class="nav-item px-2 py-2 " @click="logOut">
        <a href="#" class="nav-link bg-gradient-danger btn-sm text-white" >
        <div class="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center">
          <i class="ni ni-button-power text-white text-sm opacity-10"></i>
        </div>
        <span class="nav-link-text" :class="'ms-1'">
          Logout
          </span>
        </a>
       
      </li>
    </ul>
  </div>

  <div class="pt-3 mx-3 mt-3 sidenav-footer">
    <!-- <sidenav-card :card="{
      title: '',
      description: 'Please check our docs',
      links: [
        {
          label: 'Documentation',
          route:
            '',
          color: 'dark',
        },
        {
          label: 'Buy now',
          route:
            '',
          color: 'success',
        },
      ],
    }" /> -->
  </div>
</template>
