<template>
    <div class="card mb-4">
    <div class="card-header pb-0">
      <h6>Projects Table</h6>
    </div>
    <div class="card-body px-0 pt-0 pb-2">

    <TreeTable :value="nodes" :lazy="true" :paginator="true" :rows="rows" :loading="loading" @nodeExpand="onExpand"
      @page="onPage" :totalRecords="totalRecords">

      <Column field="field1" header="Name" :expander="true"></Column>
      <Column field="field2" header="Budget Amount"></Column>
      <Column field="field3" header="Paid Amount"></Column>
      <Column field="field4" header="Start Date"></Column>
      <Column field="field5" header="End Date"></Column>
      <Column field="field6" header="Status"></Column>
      <Column field="field7" header="Deadline"></Column>
      <Column field="field8" header="Project Image"></Column>
      <Column field="field9" header="Percentage"></Column>
      <Column field="field10" header="Team Lead"></Column>
      <Column field="field11" header="Estimated Duration"></Column>
      <Column field="field12" header="Location"></Column>
      <!-- Additional Columns for Hierarchical Data -->



    </TreeTable>
  </div>
</div>

</template>

<script setup>
import { ref, onMounted } from 'vue';
import authAxios from '../../authAxios';
import Column from 'primevue/column';
import TreeTable from 'primevue/treetable';

const nodes = ref([]);
const rows = ref(10);
const loading = ref(false);
const totalRecords = ref(0);

onMounted(async () => {
  loading.value = true;

  try {
    const response = await authAxios.get('my_projects');
    const data = response.data.payload.data;
    nodes.value = data.map(project => transformProjectToNode(project));
    totalRecords.value = response.data.payload.total;
  } catch (error) {
    console.error('Error loading data:', error);
  } finally {
    loading.value = false;
  }
});

const transformProjectToNode = (project) => {
  return {

    key: project.id,
    data: {
      field1: project.name,
      field2: project.budget_amount || '00.00',
      field3: project.paid_amount || '00.00',
      field4: project.start_date,
      field5: project.end_date,
      field6: project.status,
      field7: project.deadline || 'N/A',
      field8: project.project_image,
      field9: project.percentage || 'N/A',
      field10: project.team_lead || 'N/A',
      field11: project.estimated_duration || 'N/A',
      field12: project.location || 'N/A',
    },
    children: [
      // Additional Columns for Hierarchical Data
      {
        key: 'Transactions',
        data: {
          field1: 'Transactions',
        },
        children: [{
          key: 'Transactions',
          data: {
            field1: 'Transaction ID',
            field2: 'AMOUNT',
            field3: 'STATUS',
            field3: 'transaction_date'
          },
        },
        ...project.transactions.map(transaction => ({
          key: transaction.id,
          key: transaction.transaction_id,
          data: {
            field1: transaction.transaction_id,
            field2: transaction.amount,
            field3: transaction.payment_status,
            field4: transaction.transaction_date
          },

        })),
        ]
      },
      {
        key: 'engineers',
        data: {
          field1: 'Engineers',
        },
        children: [{
          key: 'Transactions',
          data: {
            field1: 'Name',
            field2: 'Role',
          },
        },
        ...project.engineers.map(engineer => ({
          key: engineer.name,
          data: {
            field1: engineer.name,
            field2: 'n/a',
          },

        })),
        ]
      }
     
    ]
  };
};

const onExpand = (node) => {
  if (!node.children) {
    loading.value = true;

    setTimeout(() => {
      let lazyNode = { ...node };

      // Simulate loading children nodes here
      lazyNode.children = []; // Modify as needed

      let newNodes = nodes.value.map(n => (n.key === node.key ? lazyNode : n));

      loading.value = false;
      nodes.value = newNodes;
    }, 250);
  }
};

const onPage = (event) => {
  loading.value = true;

  // Simulate delay of a backend call
  setTimeout(async () => {
    try {
      const response = await authAxios.get(`my_projects?page=${event.page}`);
      const data = response.data.payload.data;
      nodes.value = data.map(project => transformProjectToNode(project));
      totalRecords.value = response.data.payload.total;
    } catch (error) {
      console.error('Error loading data:', error);
    } finally {
      loading.value = false;
    }
  }, 1000);
};
</script>
