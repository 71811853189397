<template>
  <div class="card card-carousel overflow-hidden h-100 p-0">
    <div id="carouselExampleCaptions" class="carousel slide h-100" data-bs-ride="carousel">
      <div class="carousel-inner border-radius-lg h-100">
        <div
          v-for="(service, index) in serviceList"
          :key="service.id"
          :class="['carousel-item h-100', { active: index === 0 }]"
          :style="{
            backgroundImage: 'url(' + getBackgroundImage('carousel-1.jpg') + ')',
            backgroundSize: 'cover',
          }"
        >
          <div class="carousel-caption d-none d-md-block bottom-0 text-start start-0 ms-5">
            <div class="icon icon-shape icon-sm bg-white text-center border-radius-md mb-3">
              <i class="ni ni-camera-compact text-dark opacity-10"></i>
            </div>
            <h5 class="text-white mb-1">{{ service.name }}</h5>
            <p>{{ service.short_desc }}</p>
          </div>
        </div>
      </div>
      <button
        class="carousel-control-prev w-5 me-3"
        type="button"
        data-bs-target="#carouselExampleCaptions"
        data-bs-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button
        class="carousel-control-next w-5 me-3"
        type="button"
        data-bs-target="#carouselExampleCaptions"
        data-bs-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
  </div>
</template>

<script>
import auxioInstance from "../../authAxios";

export default {
  data() {
    return {
      serviceList: [],
    };
  },
  created() {
    this.fetchServiec();
  },
  methods: {
    async fetchServiec() {
      try {
        const response = await auxioInstance.post('list_service');
        console.log('service', response.data.payload);
        this.serviceList = response.data.payload;
      } catch (error) {
        console.log(error);
      }
    },
    getBackgroundImage(image) {
      return require('@/assets/img/' + image);
      // return require('@/assets/img/carousel-1.jpg');
    }
  }
};
</script>
